import React, { useState } from 'react';
import axios from 'axios';
import cheerio from 'cheerio';

import './FormTemplate.css'; 
import BackButtonImage from '../assets/Bundl-Back.png'; // Adjust path to your back button image

export default function FormTemplate() {
  const [results, setResults] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [mode, setMode] = useState('category'); // Default mode set to category

  const getAmazonItems = async (searchQuery, maxPages = 1) => {
    const items = [];
    const proxyUrl = 'https://ring-mountain-hisser.glitch.me/proxy?url=';
    const headers = {
      'Accept-Language': 'en-US,en;q=0.9'
    };
  
    for (let page = 1; page <= maxPages; page++) {
      const url = `https://www.amazon.com/s?k=${searchQuery}&page=${page}`;
      try {
        const response = await axios.get(`${proxyUrl}${encodeURIComponent(url)}`, { headers });
        const $ = cheerio.load(response.data);
  
        $('.s-result-item').each((index, element) => {
          const title = $(element).find('h2').text().trim();
          const wholePrice = $(element).find('.a-price-whole').first().text().replace(',', '').trim();
          const fractionalPrice = $(element).find('.a-price-fraction').first().text().trim();
          const price = wholePrice && fractionalPrice ? parseFloat(`${wholePrice}${fractionalPrice}`) : null;
          const image = $(element).find('img.s-image').attr('src');
          const itemLink = $(element).find('a.a-link-normal.s-no-outline').attr('href');
  
          if (title && price && image) {
            items.push({ title, price, image, link: `https://www.amazon.com${itemLink}` });
          }
        });
      } catch (error) {
        console.error(`Error fetching URL: ${url}`);
        console.error(error);
      }
    }
    return items;
  };

  const findCombinations = (items, budget) => {
    const combos = [];
    const combinations = (items, size, prefix = []) => {
      if (prefix.length === size) {
        const totalPrice = prefix.reduce((sum, item) => sum + item.price, 0);
        if (totalPrice <= budget) {
          combos.push({ combo: prefix, totalPrice });
        }
        return;
      }

      for (let i = 0; i < items.length; i++) {
        combinations(items.slice(i + 1), size, [...prefix, items[i]]);
      }
    };

    // Loop through possible sizes (3-4 items)
    combinations(items, 4);
    

    return combos;
  };

  const findListCombinations = (categories, budget) => {
    const combos = [];
    const combinations = (arrays, size, prefix = []) => {
      if (prefix.length === size) {
        const totalPrice = prefix.reduce((sum, item) => sum + item.price, 0);
        if (totalPrice <= budget) {
          combos.push({ combo: prefix, totalPrice });
        }
        return;
      }

      for (let i = 0; i < arrays[prefix.length].length; i++) {
        combinations(arrays, size, [...prefix, arrays[prefix.length][i]]);
      }
    };

    const size = categories.length;
    combinations(categories, size);
    return combos;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const itemsInput = formData.get('items');
    const budgetInput = formData.get('budget');
    const budget = parseFloat(budgetInput.replace(/[^\d.-]/g, ''));

    let combos = [];
    if (mode === 'category') {
      const searchQuery = itemsInput.trim();
      const items = await getAmazonItems(searchQuery, 1);
      combos = findCombinations(items, budget);
    } else {
      const searchQueries = itemsInput.split(',').map(item => item.trim());
      const categories = [];
      for (const query of searchQueries) {
        const items = await getAmazonItems(query, 1);
        categories.push(items);
      }
      combos = findListCombinations(categories, budget);
    }

    const selectedCombos = getRandomCombos(combos, 10);
    setResults(selectedCombos);
    setShowForm(false);
  };

  function getRandomCombos(combos, count) {
    const shuffledCombos = combos.slice();
    for (let i = shuffledCombos.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledCombos[i], shuffledCombos[j]] = [shuffledCombos[j], shuffledCombos[i]];
    }
    // Get a random selection of count combos
    const randomCombos = shuffledCombos.slice(0, count);

    // Sort the randomCombos by price from low to high
    randomCombos.sort((a, b) => a.totalPrice - b.totalPrice);

    return randomCombos;
  }

  const handleBack = () => {
    setResults([]);
    setShowForm(true);
    setMode('category');
  };

  const openAllLinks = (combo) => {
    combo.combo.forEach(item => {
      const newTab = window.open(item.link, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        alert('Please allow popups for this site to open multiple tabs.');
      }
    });
  };
  return (
    <>
      {showForm ? (
        <form onSubmit={handleSubmit}>
          <div className="space-y-5">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="mode" className="block text-sm font-medium leading-6 text-gray-900">
                  Mode
                </label>
                <div className="mt-2">
                  <select
                    id="mode"
                    name="mode"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2"
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                  >
                    <option value="category">Category</option>
                    <option value="list">List</option>
                  </select>
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="items" className="block text-sm font-medium leading-6 text-gray-900">
                  {mode === 'category' ?  'Enter Your Category': 'Enter Your List of Items'}
                </label>
                <div className="mt-2">
                  <textarea
                    id="items"
                    name="items"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2"
                    defaultValue={''}
                    placeholder={mode === 'category' ? 'Category': 'Item 1, Item 2, ...' }
                  />
                </div>
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="budget" className="block text-sm font-medium leading-6 text-gray-900">
                  Budget
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      name="budget"
                      id="budget"
                      autoComplete="budget"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-2"
                      placeholder="$"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-center" style={{ marginTop: '15%' }} >
            <button
              type="submit"
              className="rounded-md bg-[#5ab1e5] px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4a97c4] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4a97c4]"
            >
              Search
            </button>
          </div>
        </form>
      ) : (
        <div className="container mt-10">
           <div className="back-button-container">
            <img src={BackButtonImage} alt="Back" className="back-button-image" onClick={handleBack} style={{ width: '70px', height: '70px', cursor: 'pointer' }}  />
          </div>
          {results.map((combo, index) => (
            <div key={index} className="card">
              {combo.combo.map((item, i) => (
                <div key={i} className="item">
                  <img src={item.image} alt={item.title} />
                  <h4>{item.title.length > 45 ? `${item.title.substring(0, 45)}...` : item.title}</h4>
                  <p>${item.price.toFixed(2)}</p>
                </div>
              ))}
              <p className="total-price">Total Price: ${combo.totalPrice.toFixed(2)}</p>
              <button className="buy-button" onClick={() => openAllLinks(combo.combo)}>Buy</button>
            </div>
          ))}
        </div>
      )}
    </>
  );
}