import logo from './logo.svg';
import './App.css';
import React from 'react';
import FormTemplate from './components/FormTemplate';
import BundlLogo from './assets/Bundl-Logo.png';
import Footer from './components/Footer'



function App() {
  return (
    <div>
    <div class="flex items-center justify-center" style={{ marginTop: '8%' }}>
    <img src={BundlLogo} alt="Image Description" style={{ maxWidth: '20%', height: 'auto' }}/>
    </div>
      <div class="flex items-center justify-center" style={{ marginBottom: '10rem' }}>
       <FormTemplate/>
      </div>
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
      <Footer/>
      </div>
    </div>
    
  );
}

export default App;
